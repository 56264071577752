import React from "react"
import { Router } from "@reach/router"
import { Helmet } from "react-helmet"
import { Layout } from "../components/Layout"
import { AnonVote } from "../components/AnonVote"

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Business Meeting | Lifespring Votes</title>
      </Helmet>
      <Router basepath="/vote">
        <AnonVote path="/:uuid" />
      </Router>
    </Layout>
  )
}

export default IndexPage
