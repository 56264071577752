import React from "react"
import * as Sentry from "@sentry/browser"
import { gql, useQuery, useMutation } from "@apollo/client"
import { useStaticQuery, graphql } from "gatsby"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Radio } from "../components/Radio"
import {
  BusinessMeetingVoteQuery,
  BusinessMeetingVoteQueryVariables,
  BusinessMeetingVoteMutation,
  BusinessMeetingVoteMutationVariables,
  PollsOpenQuery,
} from "../graphql/lifespring-votes-graphql"
import { POLLS_OPEN_QUERY } from "./MemberList"

library.add(faCheckCircle)

const BUSINESS_MEETING_VOTE_QUERY = gql`
  query BusinessMeetingVoteQuery($uuid: uuid!) {
    business_meeting(where: { uuid: { _eq: $uuid } }) {
      id
      uuid
      voted
    }
  }
`

const BUSINESS_MEETING_VOTE_MUTATION = gql`
  mutation BusinessMeetingVoteMutation(
    $uuid: uuid!
    $sandy_walker: Boolean
    $voted: Boolean!
  ) {
    update_business_meeting(
      where: { uuid: { _eq: $uuid } }
      _set: { sandy_walker: $sandy_walker, voted: $voted }
    ) {
      affected_rows
    }
  }
`

export const AnonVote = (props: any) => {
  const {
    loading: pollsLoading,
    data: pollsData,
    error: pollsError,
  } = useQuery<PollsOpenQuery>(POLLS_OPEN_QUERY)
  const { loading, data, error } = useQuery<
    BusinessMeetingVoteQuery,
    BusinessMeetingVoteQueryVariables
  >(BUSINESS_MEETING_VOTE_QUERY, {
    variables: {
      uuid: props.uuid,
    },
  })

  const [castVote] = useMutation<
    BusinessMeetingVoteMutation,
    BusinessMeetingVoteMutationVariables
  >(BUSINESS_MEETING_VOTE_MUTATION)

  const staticData = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "eliel-lafavor.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  type VoteState = {
    sandy_walker: "yes" | "no" | undefined
  }

  const [vote, setVote] = React.useState<VoteState>({
    sandy_walker: undefined,
  })
  const [success, setSuccess] = React.useState(false)

  const submitVote = async (e: React.FormEvent) => {
    e.preventDefault()
    const { sandy_walker } = vote
    const resp = await castVote({
      variables: {
        voted: true,
        uuid: props.uuid,
        sandy_walker:
          sandy_walker === "yes" ? true : sandy_walker === "no" ? false : null,
      },
    })
    if (resp.data.update_business_meeting.affected_rows) {
      setSuccess(true)
    } else {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setExtras({ uuid: props.uuid, vote })
        Sentry.captureMessage("vote failed")
      })
      setSuccess(true)
    }
  }

  if (loading || pollsLoading) return null
  if (error || pollsError || !data || !data.business_meeting[0]) {
    return (
      <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg
              className="h-5 w-5 text-yellow-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="ml-3">
            <p className="text-sm leading-5 text-yellow-700">
              Invalid voting link.
              <span className="font-medium text-yellow-700 hover:text-yellow-600 transition ease-in-out duration-150 ml-2">
                Please follow the link sent to your email address to vote.
              </span>
            </p>
          </div>
        </div>
      </div>
    )
  }

  if (
    data &&
    data.business_meeting[0] &&
    data.business_meeting[0].voted === true
  ) {
    return (
      <div className="rounded-md bg-blue-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg
              className="h-5 w-5 text-blue-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm leading-5 text-blue-700">
              A vote has already been cast using this link. Thanks for
              participating!
            </p>
          </div>
        </div>
      </div>
    )
  }

  if (pollsData && pollsData.polls.length && !pollsData.polls[0].open) {
    return (
      <div className="rounded-md bg-blue-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg
              className="h-5 w-5 text-blue-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm leading-5 text-blue-700">
              Voting is not open at this time.
            </p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="w-full justify-center bg-white overflow-hidden shadow rounded-lg border border-gray-200">
      {success ? (
        <div className="my-8 flex flex-col items-center">
          <FontAwesomeIcon
            color="#68d391"
            icon={["fas", "check-circle"]}
            size="4x"
          />
          <h1 className="text-2xl">Success</h1>
          <h2 className="text-gray-400">Vote has been cast</h2>
        </div>
      ) : (
        <>
          <div className="border-b border-gray-200 px-4 py-5 sm:px-6">
            <h1 className="text-2xl">August 8th Business Meeting Vote</h1>
          </div>
          <div className="px-4 py-5 sm:p-6 mt-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Elder Nomination
            </h3>
            <div className="mt-6 sm:mt-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 py-5">
                <label className="block text-md font-medium leading-5 text-gray-700 sm:mt-px sm:pt-1">
                  Sandy Walker
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="max-w-lg flex items-center">
                    <Radio
                      variant="green"
                      selected={vote.sandy_walker === "yes"}
                      onClick={() =>
                        setVote(v => ({ ...v, sandy_walker: "yes" }))
                      }
                      label="Yes"
                    />
                    <Radio
                      variant="red"
                      selected={vote.sandy_walker === "no"}
                      onClick={() =>
                        setVote(v => ({ ...v, sandy_walker: "no" }))
                      }
                      label="No"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!!vote && (
            <form
              onSubmit={submitVote}
              className="border-t border-gray-200 px-4 py-4 sm:px-6"
            >
              <span className="inline-flex rounded-md shadow-sm">
                <button
                  type="submit"
                  className="w-full flex justify-center py-4 px-8 border border-transparent text-2xl leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                >
                  Cast Vote
                </button>
              </span>
            </form>
          )}
        </>
      )}
    </div>
  )
}
