import React from "react"

type Props = {
  variant: "green" | "red"
  selected: boolean
  onClick: () => void
  label: string
}

const styles = {
  base: {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
}

export function Radio(props: Props) {
  if (!props.selected) {
    return (
      <>
        <div
          style={{
            ...styles.base,
            border: `4px solid #cbd5e0`,
          }}
          onClick={props.onClick}
        />
        <h2 className="text-gray-600 text-xl pl-1 pr-6 font-semibold">
          {props.label}
        </h2>
      </>
    )
  }

  return (
    <>
      <div
        style={{
          ...styles.base,
          border: `4px solid ${
            props.variant === "green" ? "#68d391" : "#fc8181"
          }`,
        }}
      >
        <div
          style={{
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            backgroundColor: props.variant === "green" ? "#9ae6b4" : "#feb2b2",
          }}
        />
      </div>
      <h2
        className={`${
          props.variant === "green" ? "text-green-500" : "text-red-500"
        } text-gray-600 text-xl pl-1 pr-6 font-semibold`}
      >
        {props.label}
      </h2>
    </>
  )
}
